const TURST_PILOT_BASE_URL = 'https://api.trustpilot.com/v1'

interface ITrustPilotFetch {
  path: string
  method?: string
}

export const trustPilotFetch = async ({
  path,
  method = 'GET',
}: ITrustPilotFetch) => {
  const url = `${TURST_PILOT_BASE_URL}${path}`

  const responseJson = await fetch(url, {
    method,
    headers: {
      apikey: process.env.NEXT_PUBLIC_TRUST_PILOT_API_KEY,
    },
  })

  return responseJson.json()
}
