import Link from '@components/common/NoPrefetchLink'
import { PostOrPage } from '@tryghost/content-api'
import Image from 'next/legacy/image'

interface ITheWrapPost {
  post: Partial<PostOrPage>
}

function supplySizeConfigurationForGhost(imageUrl) {
  if (!/ghost/.test(imageUrl)) {
    return imageUrl
  }

  const regEx = /(?<rootPart>.*)\/content\/images\/(?<imgPart>.*)/
  const components = regEx.exec(imageUrl)
  return `${components.groups.rootPart}/content/images/size/w600/${components.groups.imgPart}`
}

export function TheWrapPost({ post }: ITheWrapPost) {
  return (
    <div className="transition-all duration-200 lg:scale-[0.99] hover:scale-100 group">
      <Link href={`/blog/${post?.slug}`}>
        <div className="block w-full h-full text-left cursor-pointer">
          <div className="relative w-full overflow-hidden h-[420px] lg:h-[540px] rounded-[32px]">
            {post?.feature_image && (
              <Image
                src={supplySizeConfigurationForGhost(post?.feature_image)}
                layout="fill"
                objectFit="cover"
                className="transition-transform duration-300 scale-100 group-hover:scale-105"
              />
            )}
          </div>
          <div className="pt-12 flex flex-col gap-[12px]">
            <h4 className="text-xl font-bold lg:text-3xl text-acai line-clamp-2">
              {post?.title}
            </h4>
            <p className="text-lg text-boulder lg:text-xl line-clamp-3">
              {post?.excerpt}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}
