import { starsScoreToImage } from '@utils/trustpilot/scoreMappers'
import Image from 'next/legacy/image'
import { ITrustPilotReview } from 'types/trustpilot/review'
import truncate from 'lodash/truncate'
import { formatDistanceStrict } from 'date-fns'

export function TrustpilotReviewCard({
  review,
}: {
  review: ITrustPilotReview
}) {
  const openReviewPage = () => {
    if (window) {
      window.open(`https://www.trustpilot.com/reviews/${review.id}`)
    }
  }

  return (
    <div
      className="flex flex-col items-start px-10 mx-3 cursor-pointer bg-core-white py-11 rounded-3xl"
      onClick={openReviewPage}
    >
      <div className="flex items-center mb-6">
        <Image
          src={starsScoreToImage[review.stars]}
          alt="Trustpilot rating"
          width={86}
          height={16}
        />

        <p className="ml-6 text-lg text-core-grey-80">
          {formatDistanceStrict(new Date(review.createdAt), Date.now())} ago
        </p>
      </div>

      <h4 className="mb-2 text-2xl font-semibold text-core-grey-100">
        {truncate(review.title, { length: 40 })}
      </h4>

      <p className="mb-4 text-2xl text-core-grey-100">
        {truncate(review.text, { length: 200 })}
      </p>

      <p className="text-lg text-core-grey-70">
        {truncate(review.consumer?.displayName, { length: 40 })}
      </p>
    </div>
  )
}
