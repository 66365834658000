import pMemoize from 'p-memoize'
import { TTrustPilotBusinessUnit } from 'types/trustpilot/businessUnit'
import { trustPilotFetch } from './trustpilotFetchWrapper'

const BUSINESS_UNIT_NAME = 'noissue.co'

const doGetBusinessUnit = async (): Promise<TTrustPilotBusinessUnit> => {
  try {
    const businessUnit = await trustPilotFetch({
      path: `/business-units/find?name=${BUSINESS_UNIT_NAME}`,
    })

    return businessUnit
  } catch (err) {
    throw new Error(
      `Failed to fetch noissue trustpilot business unit with an error: ${err.message}`
    )
  }
}

export const getBusinessUnit = pMemoize(doGetBusinessUnit, {
  maxAge: 24 * 60 * 60 * 1000, // 1 day
})
