import { useEffect, useState } from 'react'
import {
  getTrustpilotLatestReviewsAndSummary,
  TTrustpilotReviewsAndSummary,
} from './getLatestReviewsAndSummary'

const useTrustpilotReviews = () => {
  const [trustpilotReviews, setTrustpilotReviews] =
    useState<TTrustpilotReviewsAndSummary>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  useEffect(() => {
    const getReviews = async () => {
      try {
        setLoading(true)
        const reviews = await getTrustpilotLatestReviewsAndSummary()
        setTrustpilotReviews(reviews)
      } catch (err) {
        setError(
          err.message || `Couldn't load trustpilot reviews. Please try later.`
        )
      } finally {
        setLoading(false)
      }
    }

    getReviews()
  }, [])

  return {
    ...trustpilotReviews,
    loading,
    error,
  }
}

export { useTrustpilotReviews }
