import pMemoize from 'p-memoize'
import { TTrustPilotBusinessUnit } from 'types/trustpilot/businessUnit'
import { ITrustPilotReview } from 'types/trustpilot/review'
import { getBusinessUnit } from './getBusinessUnit'
import { trustPilotFetch } from './trustpilotFetchWrapper'

export type TTrustpilotReviewsAndSummary = {
  serviceReviews: ITrustPilotReview[]
  stars: number
  trustScore: number
} & Pick<TTrustPilotBusinessUnit, 'numberOfReviews'>

const doTrustpilotLatestReviewsAndSummary =
  async (): Promise<TTrustpilotReviewsAndSummary> => {
    try {
      const {
        id: businessUnitId,
        score,
        numberOfReviews,
      } = await getBusinessUnit()

      const { reviews } = await trustPilotFetch({
        path: `/business-units/${businessUnitId}/reviews?stars=5&perPage=10`,
      })

      return {
        serviceReviews: reviews,
        ...score,
        numberOfReviews,
      }
    } catch (err) {
      throw new Error(
        `Failed to fetch trustpilot reviews with an error: ${err.message}`
      )
    }
  }

export const getTrustpilotLatestReviewsAndSummary = pMemoize(
  doTrustpilotLatestReviewsAndSummary,
  {
    maxAge: 24 * 60 * 60 * 1000, // 1 day in milliseconds
  }
)
