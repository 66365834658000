import { TrustpilotReviewCard } from './Trustpilot/TrustpilotReviewCard'
import { TrustpilotSummaryCard } from './Trustpilot/TrustpilotSummaryCard'
import { CustomSlider } from '@components/common/CustomSlider'
import React, { useEffect } from 'react'
import { useTrustpilotReviews } from '@utils/trustpilot/useTrustpilotReviews'
import isEmpty from 'lodash/isEmpty'
import { LoaderIcon } from '@noissue-ui-kit/oldBranding/loaderIcon'

const STARS_RATING_TO_SHOW = 5

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: false,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        infinite: true,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        centerMode: true,
        infinite: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
}

export function CustomerServiceReviews() {
  const { numberOfReviews, serviceReviews, loading, error } =
    useTrustpilotReviews()

  return (
    !error && (
      <div className="flex flex-col items-start justify-around w-full pt-0 pb-12 xl:py-12 xl:px-20 xl:flex-row">
        {loading && (
          <div className="flex justify-center items-center h-80">
            <LoaderIcon
              size="m"
              className="mr-4 -mb-1"
              fillColorClass="fill-core-purple"
            />
          </div>
        )}

        {!isEmpty(numberOfReviews) && serviceReviews?.length && (
          <>
            <TrustpilotSummaryCard
              stars={STARS_RATING_TO_SHOW}
              totalReviews={numberOfReviews?.fiveStars}
              className="xl:w-30% xl:max-w-[30%] xl:mb-0 w-full max-w-full mb-10"
            />

            <div className="xl:w-[calc(70%-126px)] h-[300px] w-full max-w-full">
              <CustomSlider {...sliderSettings}>
                {serviceReviews?.map((review) => (
                  <TrustpilotReviewCard review={review} key={review.id} />
                ))}
              </CustomSlider>
            </div>
          </>
        )}
      </div>
    )
  )
}
