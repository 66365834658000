import {
  starsScoreToImage,
  starsScoreToLabel,
} from '@utils/trustpilot/scoreMappers'
import Image from 'next/legacy/image'

interface ITrustpilotSummaryCard {
  stars: number
  totalReviews: number
  className: string
}

export function TrustpilotSummaryCard({
  stars,
  totalReviews,
  className,
}: ITrustpilotSummaryCard) {
  return (
    <div
      className={`flex flex-col items-center px-10 bg-transparent xl:bg-core-white xl:py-14 rounded-3xl ${className}`}
    >
      <h4 className="mt-0 mb-3 text-3xl font-bold xl:mb-6 text-core-grey-100">
        {starsScoreToLabel[stars]}
      </h4>

      <Image
        src={starsScoreToImage[stars]}
        alt="Trustpilot rating"
        width={86}
        height={16}
      />

      <p className="mt-3 text-xl xl:mt-6 xl:mb-6 text-core-grey-90">
        Based on <strong>{totalReviews} reviews</strong>
      </p>

      <a
        href="https://www.trustpilot.com/review/www.noissue.co"
        target="_blank"
        className="hidden xl:block"
      >
        <Image
          src="/images/product/ratings/trustpilot.png"
          alt="Trustpilot logo"
          width={98}
          height={24}
        />
      </a>
    </div>
  )
}
