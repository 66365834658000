import { TShowableStarsScores } from 'types/trustpilot/review'

export const starsScoreToImage: { [key in TShowableStarsScores]: string } = {
  4: '/images/product/ratings/trustpilot-4-stars.svg',
  4.5: '/images/product/ratings/trustpilot-4_5-stars.svg',
  5: '/images/product/ratings/trustpilot-5-stars.svg',
}

export const starsScoreToLabel: { [key in TShowableStarsScores]: string } = {
  4: 'Great',
  4.5: 'Excellent',
  5: 'Excellent',
}
